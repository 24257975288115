var footerStick = function () {
  $("body").css("margin-bottom", $("#footer").outerHeight());
},
didResize = !1;
$(window).resize(function () {
    didResize = !0;
});
setInterval(function () {
    if (didResize) {
        didResize = !1;
        footerStick();
    }
}, 250);

$(document).ready(function () {
    footerStick();
    $("#navigation > .header").swipe({
        swipeDown: function (e) {
            $("#search").collapse("show");
        },
        swipeUp: function () {
            $("#search").collapse("hide");
        },
        threshold: 0,
        allowPageScroll: "none",
        excludedElements: "#search, .search-btn, .advanced-search",
    });
    $(".mega-dropdown > .dropdown-toggle").on("click", function (e) {
        e.preventDefault();
        $(this).parent().toggleClass("hover");
        $(this).parent().find("> ul.dropdown-menu").fadeToggle(200);
    });
    $(".mega-dropdown").hoverIntent(
        function () {
            if (!$("html").hasClass("no-touch")) return !0;
            $(this).addClass("hover");
            $("> ul.dropdown-menu", this).fadeIn(200);
        },
        function () {
            $(this).removeClass("hover");
            $("> ul.dropdown-menu", this).fadeOut(200);
        }
    );
    $("[placeholder]")
        .focus(function () {
            var e = $(this);
            if (e.val() == e.attr("placeholder")) {
                e.val("");
                e.removeClass("placeholder");
            }
        })
        .blur(function () {
            var e = $(this);
            if (e.val() == "" || e.val() == e.attr("placeholder")) {
                e.addClass("placeholder");
                e.val(e.attr("placeholder"));
            }
        })
        .blur();
    $(".btn").button();
    $(".ea-keyword").on("click", function (e) {
        e.preventDefault();
        $(this).toggleClass("ea-keyword--reveal");
    });
    $(".press-release-datepicker").datepicker({
          autoclose: true,
          todayHighlight: true
    }).on('changeDate', function(e) {
        window.location.href = window.location.origin + window.location.pathname + '?date=' + e.date.toLocaleDateString('en-US');
    });
	$(".embargoednews-datepicker").datepicker({
          autoclose: true,
          todayHighlight: true
    }).on('changeDate', function(e) {
        window.location.href = window.location.origin + window.location.pathname + '?date=' + e.date.toLocaleDateString('en-US')
		     + '&sort=' + document.getElementById("sort").value + '&view=' + document.getElementById("view").value;
    });
    $(".pio-datepicker").datepicker({
          autoclose: true,
          todayHighlight: true
    });
    // Toggle the briefing content using tabs
    $(".nav-tabs a").click(function(){
        $(this).tab('show');
    });

    // Bookmark tipsheet item
    $(".bookmarkTipsheetItemBtn").on("click", function (e) {
        e.preventDefault();
        var btn = $(this);
        var path = btn.data('path');
        $.ajax({
            type: 'POST',
            url: path,
            success: function(data) {
                if(data === 'success') {
                    //btn.hide();
                    location.reload();
                }
            }
        });
    });

    // Delete bookmarked tipsheet item
    $(".deleteBookmarkTipsheetItemBtn").on("click", function (e) {
        e.preventDefault();
        var btn = $(this);
        var tid = btn.data('tid');
        var path = btn.data('path');
        $.ajax({
            type: 'POST',
            url: path,
            success: function(data) {
                if(data === 'success') {
                    //btn.hide();
                    //$('#tid-'+tid).hide();
                    location.reload();
                }
            }
        });
    });

    // collapse feature for tipsheet item
    $('.collapse').on('shown.bs.collapse', function() {
        $(this).parent().find(".glyphicon-plus").removeClass("glyphicon-plus").addClass("glyphicon-minus");

        // update view count
        var id = $(this).parent().find("a").data('id');
        $.ajax({
            type: 'GET',
            url: '/tipsheet-item/add-page-view/'+id
        });
    }).on('hidden.bs.collapse', function() {
        $(this).parent().find(".glyphicon-minus").removeClass("glyphicon-minus").addClass("glyphicon-plus");
    });

    // multiselect journal dropdown
    $('#selectizeMultiFilter').selectize({});

    // set video url in azure media player if video element exists on page
	/*
    let mediaPlayer = $('#azuremediaplayer');
    if (mediaPlayer.length !== 0) {
        let url = mediaPlayer.data('url');
        $.ajax({
            type: 'GET',
            url: url,
            success: function (data) {
                try {
                    let azurePlayer = amp("azuremediaplayer");
                    azurePlayer.src([{
                        src: data.SmoothStreamingUrl,
                        type: "application/vnd.ms-sstr+xml"
                    }]);
                } catch (e) {
                    $('#azuremediaplayer').remove();
                    $('.embargo-msg').toggleClass('hidden');
                }
            }
        });
    }
	*/
	
	// set video url in MKPlayer if video element exists on page
	// MediaKind MKPlayer integration
	let mediaPlayer = $('#video-container');
	var videoElement = document.getElementById("videoElement");
	if (mediaPlayer.length !== 0) {
		let url = mediaPlayer.data('url');
		let poster = mediaPlayer.data('poster');
		$.ajax({
			type: 'GET', 
			url: url,
			success: function (data) {
				console.log(data);
				try {
					// 1. Grab the video container
					const videoContainer = document.getElementById("video-container");
					
					// 2. Prepare the player configuration
					const playerConfig = {
						key: atob(data.PlayerLicense), //Decode Base64 key
						playback: {
							muted: false,
							autoplay: false
						},
						ui: true,
						// Subscribe to player events
						events: {
							[mkplayer.MKPlayerEvent.Error]: (event) => {
								console.log("Encountered player error: ", JSON.stringify(event));
							},
							[mkplayer.MKPlayerEvent.TimeChanged]: (event) => {
								console.log("Current player position: ", event.time);
							},
							[mkplayer.MKPlayerEvent.Ready]: (event) => {
								//console.log("Start player position: ", document.getElementById("videoElement").currentTime);
								player.seek(0);
								//console.log("Fixed Start player position: ", document.getElementById("videoElement").currentTime);
							}
						}
					};
					
					// 3. Initialize the player with video container and player configuration
					let player = new mkplayer.MKPlayer(videoContainer, playerConfig);
					const sourceConfig = {
						poster: poster,
						hls: data.HlsStreamingUrl,
						dash: data.MpegDashStreamingUrl
					};
					player.setVideoElement(videoElement);
					player.load(sourceConfig)
					.then(() => {
						// you can also get notified when subscribed to `SourceLoaded` event.
						console.log("Source loaded successfull!");
					})
					.catch((error) => {
						console.error("An error occurred while loading the source!");
					});
				} catch (e) {
					$('#video-container').remove();
                    $('.embargo-msg').toggleClass('hidden');
				}
				
			}
		});
	}
					
});
