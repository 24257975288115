import '../css/eurekalertstyle.css';
import '../css/bootstrap.min.css';
import '../css/app.css';
import '../css/doe.css';
import '../css/highlights.css';
import '../css/reimagined.scss';
import '../css/account.scss';
import '../css/datepicker.css';
import '../css/tipsheet.scss';
import '../css/selectize/selectize.css';
import '../css/selectize/selectize.default.css';
import '../css/selectize/selectize.bootstrap4.css';
//import '../mediakind/mkplayer-ui.css';

// TODO: get modernizr working
// require('./lib/modernizr-2.6.2.min.js');
require('./lib/jquery.min.js');
require('./lib/jquery-ui.min.js');
require('./plugins.e4.js');
require('./main.js');
require('./e4.js');
require('./lib/bootstrap-datepicker.js');
require('./standalone/selectize.js');
//require('../mediakind/mkplayer.js');


import $ from "jquery";
global.$ = global.jQuery = $;
